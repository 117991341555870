import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLanguage } from '@hooks/useLanguage';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '@store/index';
import { UploadInProgress } from '@features/employeeManagement/pages/EmployeeOverview/EmployeeOverview.styles';
import { EmployeeManagementSelectors } from '@features/employeeManagement/store/employeeManagement.selectors';
import { FormatDate } from '@components/FormatDate';
import { BULK_IMPORT_STEPS } from '@localTypes/index';
import theme from '@theme/theme';

const aDayTS = 86400;

const ImportEmployeesHelper = () => {
  const { formatMessage } = useIntl();
  const activeLanguage = useLanguage();

  const step = useAppSelector(EmployeeManagementSelectors.currentStep);
  const bulkUploadFiles = useAppSelector(EmployeeManagementSelectors.getBulkUploadFiles);

  const isHansefitUpload = useMemo(
    () => Boolean(bulkUploadFiles?.data?.hansefitBulkUpload),
    [bulkUploadFiles?.data?.hansefitBulkUpload],
  );

  const memoizedTextProgress = useMemo(() => {
    if (isHansefitUpload) {
      return formatMessage({
        id: 'employee_management.overview.upload.progress.notify',
        defaultMessage: 'It might takes some time. We will notify you once it’s completed',
      });
    }
    return formatMessage({
      id: 'employee_management.overview.review.content',
      defaultMessage: 'Please review and apply the imported employees.',
    });
  }, [formatMessage, isHansefitUpload]);

  if (
    isHansefitUpload ||
    step === BULK_IMPORT_STEPS.IMPORT_IN_PROGRESS ||
    step === BULK_IMPORT_STEPS.REVIEW_STEP
  ) {
    return (
      <UploadInProgress>
        <Box display='flex'>
          {isHansefitUpload && (
            <Box
              bgcolor={theme.palette.primary.main}
              color={theme.palette.primary.contrastText}
              borderRadius='4px'
              padding='2px 5px'
              mr={2}>
              <Typography className='hansefit-text'>HANSEFIT</Typography>
            </Box>
          )}
          <Box>
            <Typography className='text-progress'>
              {formatMessage({
                id: 'employee_management.overview.upload.progress',
                defaultMessage: 'Import in progress',
              })}
              : <span>{memoizedTextProgress}</span>
            </Typography>
          </Box>
        </Box>
        {bulkUploadFiles.data.companyBulkUpload?.lastModifiedAtTS && (
          <Box className='started-date'>
            {formatMessage(
              {
                id: 'employee_management.overview.stored_time.label',
                defaultMessage: 'Your import is temporarily stored until {date}',
              },
              {
                date: FormatDate(
                  bulkUploadFiles.data.companyBulkUpload.lastModifiedAtTS * 1000 + aDayTS * 1000,
                  bulkUploadFiles.data.companyBulkUpload.lastModifiedAtTS * 1000 + aDayTS * 1000,
                ),
              },
            )}
            {step === BULK_IMPORT_STEPS.REVIEW_STEP && (
              <Link
                to={`/${activeLanguage}/employee-management/bulk-upload`}
                className='btn-import'>
                <Box display='inline-block' ml={2}>
                  {formatMessage({
                    id: 'employee_management.overview.review.label',
                    defaultMessage: 'Review import',
                  })}
                </Box>
              </Link>
            )}
          </Box>
        )}
      </UploadInProgress>
    );
  }
  return <></>;
};

export default ImportEmployeesHelper;
