import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosWithConfig } from 'api';
import { RootState, BaseErrorResponse, ISelfRegistration } from 'types';

export const getRegistrationSettings = createAsyncThunk<
  any,
  { companyId?: number },
  { state: RootState }
>('settings/getRegistrationSettings', async ({ companyId }, { rejectWithValue, getState }) => {
  const state = getState();
  companyId = companyId || state.company.activeCompany?.companyId;
  const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${companyId}`;

  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.get(endpoint);

    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'string') {
        const convertedKey = data[key].trim().toLowerCase();
        if (convertedKey === 'yes' || convertedKey === 'no') {
          data[key] = convertedKey === 'yes';
        }
      }
    });
    data['euShare'] = data['euShare'].toString().replace('.', ',');
    data['enable'] = data['password'] || data['invitationRequired'];
    data['passwordRequired'] = data['password'];
    data['signupPassword'] = data.password ? data['signupPassword'] : '';
    data['allowSEPA'] = data['paymentProviders'].includes('sepa');
    data['registrationDeadlineSwitcher'] = !!data['registrationDeadline'];
    data['registrationDeadline'] = data['registrationDeadline'] || '';
    if (!data['domainValidation']) data['validEmailDomains'] = '';
    return data as ISelfRegistration;
  } catch (e) {
    const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
    return rejectWithValue(error.response?.data);
  }
});
