import { Box, styled } from '@mui/material';

export const HeaderWrapper = styled('div')(() => ({
  '.btn-import': {
    textDecoration: 'none',
    marginRight: 16,
  },
}));

export const UploadInProgress = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  boxShadow: `0 10px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
  backgroundColor: theme.palette.background.paper,
  marginTop: '16px',
  padding: '9px 16px',

  '.started-date': {
    color: theme.palette.neutralDark.main,
    fontSize: 16,
    textAlign: 'right',
  },
  '.btn-import': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  '& .hansefit-text': {
    fontSize: 14,
  },
  '& .text-progress': {
    fontWeight: 'bold',
    '& span': {
      color: theme.palette.neutralDark.contrastText,
      fontWeight: 'normal',
    },
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.pending': {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 4,
    boxShadow: `0 10px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
    background: theme.palette.background.paper,
    padding: '9px 24px 9px 16px',
    marginTop: 16,

    '&__title': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },

    '&__link': {
      fontFamily: 'Roboto-Medium',
      fontSize: 16,
      lineHeight: 1.38,
      fontWeight: 500,
      color: theme.palette.primary.main,
      textDecoration: 'unset',
    },
  },
}));

export const CtaContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
