import { useAppSelector } from 'store';
import { CompanySelectors } from '../../../../company/store/company.selectors';
import { CCPSelect, CCPTextField, Spacer } from 'components';
import { useFormContext } from 'react-hook-form';
import { GlobalCustomFieldsWrapper } from './GlobalCustomFields.styles';

import { CompanyCustomFieldType } from 'types';
import { Box, MenuItem, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { addressGlobalCustomFieldsSort, globalCustomFieldsSort } from './hardcodedData';
import { ICountriesList } from 'features/company/store/interfaces';
import { useTranslationLanguage } from '@hooks/useTranslationLanguage';

const GlobalCustomFields = ({
  width = 'auto',
  isAddressFields,
}: {
  width?: 'auto' | number;
  isAddressFields?: boolean;
}) => {
  const intl = useIntl();
  const translationKey = useTranslationLanguage();
  const {
    getCompany: { data: companyData },
  } = useAppSelector(CompanySelectors.getApi);
  const countries = useAppSelector(state => state.company.countriesList.data) || [];

  const { control } = useFormContext();

  const prioritiesGroup = countries.reduce(function (arr: any, a: ICountriesList) {
    arr[a.priority] = arr[a.priority] || [];
    arr[a.priority].push(a);
    return arr;
  }, Object.create(null));

  let sortedCountries: ICountriesList[] = [];

  Object.keys(prioritiesGroup).forEach(group => {
    prioritiesGroup[group] = prioritiesGroup[group].sort((a: ICountriesList, b: ICountriesList) =>
      a.name[translationKey].localeCompare(b.name[translationKey]),
    );
    sortedCountries = [...sortedCountries, ...prioritiesGroup[group]];
  });

  const getLabel = (elem: CompanyCustomFieldType) => elem.name[translationKey] || '';

  const getGlobalCustomFields = (isAddressFields: boolean) =>
    globalCustomFieldsSort.map((item, i) => {
      if (
        (isAddressFields && addressGlobalCustomFieldsSort.includes(item[0])) ||
        (!isAddressFields && !addressGlobalCustomFieldsSort.includes(item[0]))
      ) {
        const firstElem = companyData?.globalCustomFields.filter(
          globalItem => globalItem.key === item[0],
        )[0];
        if (item.length > 1) {
          let secondElem = companyData?.globalCustomFields.filter(
            globalItem => globalItem.key === item[1],
          )[0];
          if (firstElem && secondElem) {
            return (
              <Box className='content' width={width} key={i}>
                {firstElem.inputType !== 'enum' && (
                  <CCPTextField
                    key={firstElem.key}
                    type={firstElem.inputType}
                    control={control}
                    name={`globalCustomFields.${firstElem.key}`}
                    required={firstElem.mandatory === 'yes'}
                    label={getLabel(firstElem)}
                  />
                )}
                {secondElem.inputType !== 'enum' && (
                  <CCPTextField
                    key={secondElem.key}
                    type={secondElem.inputType}
                    control={control}
                    name={`globalCustomFields.${secondElem.key}`}
                    required={secondElem.mandatory === 'yes'}
                    label={getLabel(secondElem)}
                  />
                )}
              </Box>
            );
          } else {
            if (firstElem) {
              secondElem = firstElem;
            }
            return secondElem && secondElem.inputType !== 'enum' ? (
              <CCPTextField
                key={secondElem.key}
                type={secondElem.inputType}
                control={control}
                width={width}
                name={`globalCustomFields.${secondElem.key}`}
                required={secondElem.mandatory === 'yes'}
                label={getLabel(secondElem)}
              />
            ) : null;
          }
        }
        if (firstElem) {
          return firstElem.key === 'hansefit_country' ? (
            <CCPSelect
              key={firstElem.key}
              control={control}
              name={`globalCustomFields.${firstElem.key}`}
              required={firstElem.mandatory === 'yes'}
              label={getLabel(firstElem)}
              width={width}>
              {sortedCountries.map(country => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name[translationKey]}
                </MenuItem>
              ))}
            </CCPSelect>
          ) : (
            firstElem.inputType !== 'enum' && (
              <CCPTextField
                key={firstElem.key}
                type={firstElem.inputType}
                control={control}
                width={width}
                name={`globalCustomFields.${firstElem.key}`}
                required={firstElem.mandatory === 'yes'}
                label={getLabel(firstElem)}
              />
            )
          );
        }
        return null;
      }
      return null;
    });
  return companyData && companyData.globalCustomFields.length > 0 ? (
    <GlobalCustomFieldsWrapper>
      {isAddressFields && (
        <Typography variant='h4' marginBottom={0}>
          {intl.formatMessage({
            id: 'form.employee.field.global_address.label',
            defaultMessage: 'Address',
          })}
          :
        </Typography>
      )}
      {getGlobalCustomFields(!!isAddressFields)}
      <Spacer height={0} />
    </GlobalCustomFieldsWrapper>
  ) : null;
};

export default GlobalCustomFields;
