import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CustomStatsWrapper from '../CustomStatsWrapper.styles';

import { FetchingStatus, FiltersProps } from 'types';
import { StatsActions } from 'features/stats/store';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import StatsInfo from '@features/stats/components/StatsInfo';
import StatsTable from '@features/stats/components/StatsTable';
import { getFirstDayOfMonth, getLastDayOfMonth } from '@utils/getStatsMonth';

const couponsColumns = [
  {
    intlId: 'view.stats.table.id',
    columnName: 'id',
    columnTitle: 'ID',
  },
  {
    intlId: 'view.stats.table.service',
    columnName: 'provider',
    columnTitle: 'Service provider',
  },
  {
    intlId: 'view.stats.table.coupon_used',
    columnName: 'coupon_used',
    columnTitle: '# Coupon used',
  },
];

const CouponUsagePage = () => {
  const dispatch = useAppDispatch();
  const { coupons } = useAppSelector(state => state.stats);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const [filters, setFilters] = useState<FiltersProps>({
    startMonth: new Date().getMonth(),
    endMonth: new Date().getMonth(),
    startYear: new Date().getFullYear(),
    endYear: new Date().getFullYear(),
  });

  const couponsCount = useMemo(
    () => coupons?.data?.reduce((acc, item) => acc + item.coupons, 0) || 0,
    [coupons?.data],
  );

  const getCouponsList = useCallback(async () => {
    const { startMonth, endMonth, startYear, endYear } = filters;

    if (!activeCompany?.companyId) return;

    dispatch(
      StatsActions.getCoupons({
        companyId: activeCompany?.companyId,
        startMonth: getFirstDayOfMonth(startYear, startMonth),
        endMonth: getLastDayOfMonth(endYear, endMonth),
      }),
    );
  }, [dispatch, activeCompany?.companyId, filters]);

  const updateFiltersHandler = (filters?: FiltersProps): void => {
    if (filters) setFilters(filters);
  };

  useEffect(() => {
    getCouponsList();
  }, [getCouponsList]);

  const hasError = useMemo(
    () => coupons.fetchingStatus === FetchingStatus.REJECTED,
    [coupons.fetchingStatus],
  );
  const isLoading = useMemo(
    () => coupons.fetchingStatus === FetchingStatus.PENDING,
    [coupons.fetchingStatus],
  );
  const couponsList = useMemo(() => coupons.data || [], [coupons.data]);

  return (
    <CustomStatsWrapper>
      <Box className='container'>
        <StatsInfo
          data={couponsList}
          count={couponsCount}
          filters={filters}
          fileName='coupon-usage'
          className='yellow'
          isLoading={isLoading}
          updateFilters={updateFiltersHandler}
          hasError={hasError}
        />
        <StatsTable
          isLoading={isLoading}
          hasError={hasError}
          tableColumns={couponsColumns}
          statsList={couponsList}
        />
      </Box>
    </CustomStatsWrapper>
  );
};

export default CouponUsagePage;
