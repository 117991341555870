import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { getCouponsExtraReducer } from './actions/async/getCoupons/getCoupons.reducer';
import { getCheckInsExtraReducer } from './actions/async/getCheckIns/getCheckIns.reducer';
import { getActiveMembersExtraReducer } from './actions/async/getActiveMembers/getActiveMembers.reducer';

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getCouponsExtraReducer(builder);
    getCheckInsExtraReducer(builder);
    getActiveMembersExtraReducer(builder);
  },
});

export const { name, actions } = statsSlice;
export const statsReducer = statsSlice.reducer;
