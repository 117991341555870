import { BasicEmployee, CustomFieldTypes } from '@localTypes/index';

const valueToType = (
  value: BasicEmployee['customFields'][keyof BasicEmployee['customFields']],
  type?: CustomFieldTypes,
) => {
  switch (type) {
    case 'integer':
      return parseInt(value.toString(), 10);
    case 'float':
      return parseFloat(value.toString());
    default:
      return value;
  }
};

export default valueToType;
