import { GlobalState } from './interfaces';

export const initialState: GlobalState = {
  guardPopups: {
    activationLetters: false,
    showActivationLettersAddUser: false,
    registrationDeadline: false,
    selfRegistrationChanges: false,
  },
};
