import { FetchingStatus } from 'types';

import { StatsState } from './interfaces';

export const initialState: StatsState = {
  checkIns: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
  },
  coupons: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
  },
  activeMembers: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
  },
};
