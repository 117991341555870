import React, { useEffect, useMemo, useState } from 'react';
import AddEmployeeModal from 'features/employeeManagement/components/EmployeeOverview/Modals/AddEmployeeModal';
import SuccessInvitationModal from 'features/employeeManagement/components/EmployeeOverview/Modals/SuccessInvitationModal';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeManagementSelectors } from '../../store/employeeManagement.selectors';
import { BULK_IMPORT_STEPS, FetchingStatus, ListType } from 'types';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { Spacer } from 'components';
import { Container, HeaderContainer, HeaderWrapper } from './EmployeeOverview.styles';
import { Link } from 'react-router-dom';
import EmployeeTable from '../../components/EmployeeOverview/EmployeeTable';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';
import { CompanyActions } from 'features/company/store';
import { useLanguage } from 'hooks';
import { SettingsActions } from 'features/settings/store';
import CompanyCustomFieldModal from 'features/employeeManagement/pages/EmployeeBulkUpload/CompanyCustomFieldModal';
import { useCookies } from 'react-cookie';
import { checkTypeParameter } from 'utils/contractDependencies';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import GoToSettings from 'components/Modal/GoToSettings';
import GetSignupLinkModal from 'features/employeeManagement/components/EmployeeOverview/Modals/GetSignupLinkModal';
import ImportEmployeesButton from '@features/employeeManagement/components/EmployeeOverview/ImportEmployeesButton';
import ImportEmployeesHelper from '@features/employeeManagement/components/EmployeeOverview/ImportEmployeesHelper';

const aDayTS = 86400;
const currentTimestamp = +new Date();

const EmployeeOverviewPage: React.FC = () => {
  const intl = useIntl();
  const activeLanguage = useLanguage();
  const dispatch = useAppDispatch();
  const { data: employees, fetchingStatus } = useAppSelector(
    EmployeeManagementSelectors.getGetEmployeesApi,
  );
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const companyApi = useAppSelector(CompanySelectors.getApi);
  const company = companyApi.getCompany;
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const settingsSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );
  const step = useAppSelector(EmployeeManagementSelectors.currentStep);
  const bulkUploadFiles = useAppSelector(EmployeeManagementSelectors.getBulkUploadFiles);
  const pendingEmployeesList = employees?.filter((employee: any) => employee.status === 'applied');
  const [showCustomFieldModal, setShowCustomFieldModal] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [needsToFillAL, setNeedsToFillAL] = useState<boolean>(false);
  const isDirectPay = useAppSelector(store => store.settings.selfRegistration.directPay);
  const [cookies] = useCookies(['hideCustomerField']);
  const hideCustomerField = cookies.hideCustomerField || {};

  const hasBulkUpload =
    bulkUploadFiles.fetchingStatus === FetchingStatus.FULFILLED &&
    (bulkUploadFiles.data.companyBulkUpload || bulkUploadFiles.data.hansefitBulkUpload);

  const getCompanyCustomFieldList = async () => {
    if (!activeCompany?.companyId) {
      return;
    }
    const companyCustomFieldData = await dispatch(
      SettingsActions.getCompanyCustomField({
        companyId: activeCompany?.companyId,
      }),
    ).unwrap();

    if (
      companyCustomFieldData &&
      !companyCustomFieldData.length &&
      !showCustomFieldModal &&
      !hideCustomerField[activeCompany.companyId]
    ) {
      setShowCustomFieldModal(true);
    }
  };

  useEffect(() => {
    if (
      activeCompany?.companyId &&
      ((company.fetchingStatus === FetchingStatus.FULFILLED &&
        company?.data?.id !== activeCompany.companyId) ||
        company.fetchingStatus === FetchingStatus.IDLE)
    ) {
      dispatch(CompanyActions.getCompany({ companyId: activeCompany.companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.companyId, dispatch]);

  useEffect(() => {
    if (activeCompany?.companyId) {
      const companyId = activeCompany.companyId;
      dispatch(EmployeeManagementActions.getEmployees({ companyId })).catch(err =>
        console.log('Error fetching employees:', err),
      );

      dispatch(EmployeeManagementActions.getBulkUploadFile({ companyId })).catch(err =>
        console.log('Error fetching bulk upload file:', err),
      );
      getCompanyCustomFieldList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.companyId]);

  useEffect(() => {
    if (
      bulkUploadFiles.data &&
      bulkUploadFiles.data.companyBulkUpload &&
      bulkUploadFiles.data.companyBulkUpload.filePath &&
      bulkUploadFiles.fetchingStatus === FetchingStatus.FULFILLED &&
      activeCompany?.companyId &&
      step !== BULK_IMPORT_STEPS.REVIEW_STEP
    ) {
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.REVIEW_STEP));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkUploadFiles.data, activeCompany?.companyId]);

  useEffect(() => {
    dispatch(SettingsActions.getRegistrationSettings({}));
    if (activeCompany) {
      const { companyId } = activeCompany;

      dispatch(SettingsActions.getSettings({ companyId }));
      dispatch(
        SettingsActions.getDistributionList({ companyId, listType: ListType.ACTIVATION_LETTER }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      settingsSettings &&
      activationLetterDistributionListFetchingStatus === FetchingStatus.FULFILLED
    ) {
      setNeedsToFillAL(
        settingsSettings.activationLetterRecipientMode.toLowerCase() === 'cu' &&
          activationLetterDistributionList.length === 0,
      );
    }
  }, [
    activationLetterDistributionList,
    activationLetterDistributionListFetchingStatus,
    settingsSettings,
    settingsSettings.activationLetterRecipientMode,
  ]);

  useEffect(() => {
    if (
      step === BULK_IMPORT_STEPS.REVIEW_STEP &&
      bulkUploadFiles.data &&
      bulkUploadFiles.data.companyBulkUpload &&
      bulkUploadFiles.data.companyBulkUpload.lastModifiedAtTS - currentTimestamp > aDayTS
    ) {
      dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
    }
  }, [dispatch, step, bulkUploadFiles.data]);

  const checkActivationLettersSettingsIsCorrect = () => {
    if (needsToFillAL) {
      setShowSettingsModal(true);
      return false;
    }
    return true;
  };

  const canCreateAndEdit = useMemo(() => {
    if (isDirectPay) {
      return false;
    }
    return activeContract && checkTypeParameter(activeContract, 'createEditEUPossible');
  }, [isDirectPay, activeContract]);

  return (
    <Container>
      <HeaderContainer>
        <Typography data-test-id='pageTitle' variant='h2'>
          {intl.formatMessage({
            id: 'employee_management.overview.title',
            defaultMessage: 'Employees',
          })}
        </Typography>
        {isDirectPay && <GetSignupLinkModal />}
        {canCreateAndEdit && (
          <HeaderWrapper>
            <Stack direction='row' justifyContent='flex-end' spacing={2}>
              <ImportEmployeesButton
                checkActivationLettersSettingsIsCorrect={checkActivationLettersSettingsIsCorrect}
              />
              <AddEmployeeModal
                checkActivationLettersSettingsIsCorrect={checkActivationLettersSettingsIsCorrect}
              />
              <GoToSettings
                isUserCreate
                isOpen={showSettingsModal}
                handleClose={() => setShowSettingsModal(false)}
                data-test-id='goToSettingsButton'
              />
            </Stack>
          </HeaderWrapper>
        )}
        <SuccessInvitationModal />
      </HeaderContainer>
      <ImportEmployeesHelper />
      {!!pendingEmployeesList?.length && (
        <Box className='pending'>
          <Typography className='pending__text' variant='body1'>
            <strong>
              {intl.formatMessage({
                id: 'employee_management.overview.pending.title',
                defaultMessage: 'Pending requests:',
              })}
            </strong>{' '}
            {intl.formatMessage(
              {
                id: 'employee_management.overview.pending',
                defaultMessage: 'you have {amount} pending requests',
              },
              { amount: pendingEmployeesList.length },
            )}
          </Typography>
          <Link
            className='pending__link'
            to={`/${activeLanguage}/employee-management/pending-requests`}>
            {intl.formatMessage({
              id: 'employee_management.overview.pending.view',
              defaultMessage: 'View requests',
            })}
          </Link>
        </Box>
      )}
      <Spacer size='lg' />
      <EmployeeTable isDirectPay={isDirectPay} />
      {showCustomFieldModal &&
        fetchingStatus === FetchingStatus.FULFILLED &&
        bulkUploadFiles.fetchingStatus === FetchingStatus.FULFILLED &&
        !employees?.length &&
        !hasBulkUpload && (
          <CompanyCustomFieldModal
            open={showCustomFieldModal}
            handleClose={() => setShowCustomFieldModal(false)}
            activeCompanyId={activeCompany?.companyId}
          />
        )}
    </Container>
  );
};

export default EmployeeOverviewPage;
