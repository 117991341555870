import {
  ActiveMembersTableProps,
  CheckInsTableProps,
  CouponTableProps,
  StatsTableProps,
} from '@localTypes/stats';

export const isActiveMembersRow = (item: StatsTableProps): item is ActiveMembersTableProps =>
  !!(item as ActiveMembersTableProps).customer_id;
export const isCheckInsRow = (item: StatsTableProps): item is CheckInsTableProps =>
  !!(item as CheckInsTableProps).checkins;
export const isCouponRow = (item: StatsTableProps): item is CouponTableProps =>
  !!(item as CouponTableProps).coupons;
