import { Box, Typography } from '@mui/material';
import { Spacer } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useAppSelector } from 'store';
import { EmployeeDetailsStyled } from '../EmployeeDetails/EmployeeDetails.styles';
import { useIntl } from 'react-intl';
import { useTranslationLanguage } from '@hooks/useTranslationLanguage';
import { addressGlobalCustomFieldsSort } from '../../EmployeeOverview/GlobalCustomFields/hardcodedData';
import { BasicEmployee } from '@localTypes/index';

type CustomFieldsProp = {
  data: BasicEmployee['globalCustomFields'];
  isAddressFields?: boolean;
};

export const GlobalCustomFields = ({ data, isAddressFields }: CustomFieldsProp) => {
  const intl = useIntl();

  const translationKey = useTranslationLanguage();
  const alternateLanguage = translationKey === 'de' ? 'en' : 'de';

  const countries = useAppSelector(state => state.company.countriesList.data) || [];

  const {
    getCompany: { data: companyData },
  } = useAppSelector(CompanySelectors.getApi);

  const companyCustomFields = companyData?.globalCustomFields;

  const renderCustomFields = (fields: BasicEmployee['globalCustomFields']) => {
    const customFieldList: JSX.Element[] = [];

    if (companyCustomFields) {
      companyCustomFields.forEach(compField => {
        if (!isAddressFields && !addressGlobalCustomFieldsSort.includes(compField.key))
          customFieldList.push(
            <Box key={compField.key}>
              <Typography variant='body2' className='employeeInfoHeader'>
                {compField.name[translationKey]
                  ? compField.name[translationKey]
                  : compField.name[alternateLanguage]}
              </Typography>
              <Spacer height={16} />
              <Typography variant='body2' className='infoData'>
                {compField.key === 'hansefit_country'
                  ? countries.filter(country => country.code === fields[compField.key])[0]?.name[
                      translationKey
                    ]
                  : fields[compField.key]}
              </Typography>
              <Spacer height={48} />
            </Box>,
          );
      });
    }

    return customFieldList;
  };

  let addressData =
    [] as BasicEmployee['globalCustomFields'][keyof BasicEmployee['globalCustomFields']][];
  if (data && isAddressFields) {
    addressData = addressGlobalCustomFieldsSort.map(item => data[item]).filter(item => item);
  }

  return (
    <EmployeeDetailsStyled>
      {data && (
        <>
          {isAddressFields
            ? addressData.length > 0 && (
                <Box className='infoColumn'>
                  <Typography variant='body2'>
                    {intl.formatMessage({
                      id: 'form.employee.field.global_address.label',
                      defaultMessage: 'Address',
                    })}
                    :
                  </Typography>
                  <Typography variant='body2' className='infoData'>
                    {addressData.join(', ')}
                  </Typography>
                </Box>
              )
            : renderCustomFields(data)}
        </>
      )}
    </EmployeeDetailsStyled>
  );
};
