import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLanguage } from '@hooks/useLanguage';
import { useNavigate } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import { CCPDefaultButton } from '@components/index';
import { useAppDispatch, useAppSelector } from '@store/index';
import { CompanySelectors } from '@features/company/store/company.selectors';
import { EmployeeManagementSelectors } from '@features/employeeManagement/store/employeeManagement.selectors';
import { EmployeeManagementActions } from '@features/employeeManagement/store';
import { S3CredentialsActions } from '@features/shared/s3Credentials/store';
import { BULK_IMPORT_STEPS } from '@localTypes/index';

const ImportEmployeesButton = ({
  checkActivationLettersSettingsIsCorrect,
}: {
  checkActivationLettersSettingsIsCorrect: () => boolean;
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeLanguage = useLanguage();

  const step = useAppSelector(EmployeeManagementSelectors.currentStep);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const bulkUploadFiles = useAppSelector(EmployeeManagementSelectors.getBulkUploadFiles);

  const isHansefitUpload = useMemo(
    () => Boolean(bulkUploadFiles?.data?.hansefitBulkUpload),
    [bulkUploadFiles?.data?.hansefitBulkUpload],
  );

  const goToBulkUpload = () => {
    if (!checkActivationLettersSettingsIsCorrect()) {
      return;
    }
    navigate(`/${activeLanguage}/employee-management/bulk-upload`);
  };

  const onCancelImportProgress = () => {
    if (
      activeCompany?.companyId &&
      bulkUploadFiles.data &&
      bulkUploadFiles.data.companyBulkUpload
    ) {
      dispatch(
        EmployeeManagementActions.cancelImportProgress({
          companyId: activeCompany?.companyId,
          confirmationId: bulkUploadFiles.data.companyBulkUpload?.confirmationId,
        }),
      );
      dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
      dispatch(S3CredentialsActions.resetGetS3CredentialsState());
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
    }
  };

  //show button when import is in progress(while file loading or some another user loaded his file)
  if (isHansefitUpload || step === BULK_IMPORT_STEPS.UPLOAD_IN_PROGRESS) {
    return (
      <CCPDefaultButton
        variant='outlined'
        sx={{ marginRight: '10px' }}
        disabled={isHansefitUpload}
        onClick={onCancelImportProgress}
        data-test-id='uploadIsInProgressButton'>
        {formatMessage({
          id: 'employee_management.overview.upload.progress',
          defaultMessage: 'Upload in progress',
        })}
        &nbsp; <SyncIcon style={{ height: 20, width: 20 }} />
      </CCPDefaultButton>
    );
  }

  //show button when import is in progress(when file was loaded on server)
  if (step === BULK_IMPORT_STEPS.REVIEW_STEP) {
    return (
      <CCPDefaultButton
        variant='outlined'
        disabled={isHansefitUpload}
        onClick={goToBulkUpload}
        data-test-id='importReviewButton'>
        {formatMessage({
          id: 'employee_management.overview.cta.review',
          defaultMessage: 'Review import',
        })}
      </CCPDefaultButton>
    );
  }

  //show default button
  return (
    <>
      <CCPDefaultButton
        variant='outlined'
        onClick={goToBulkUpload}
        data-test-id='importEmployeesButton'>
        {formatMessage({
          id: 'employee_management.overview.cta.import',
          defaultMessage: 'Import employees',
        })}
      </CCPDefaultButton>
    </>
  );
};

export default ImportEmployeesButton;
