import { useState, ChangeEvent, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
  ClickAwayListener,
  Tooltip,
  MenuItem,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { isEqual } from 'lodash';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { SelfRegistrationWrapper, ValidationMessage } from './SelfRegistration.styles';
import { useIntl } from 'react-intl';
import { FetchingStatus, ISelfRegistration, ListType } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { SettingsActions } from 'features/settings/store';
import {
  AlertModal,
  CCPDefaultButton,
  Divider,
  ModalError,
  PromptModal,
  Spacer,
  Tickbox,
} from 'components';
import { DefaultTextField } from 'components/Form/TextField/DefaultTextField.styles';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router';
import { useLanguage } from 'hooks';
import Loader from 'components/Loader';
import { euShareRegExp, signupPasswordRegExp } from 'utils';
import { CompanySelectors } from 'features/company/store/company.selectors';
import GoToSettings from 'components/Modal/GoToSettings';
import InputTag from 'components/InputTag';
import theme from '@theme/theme';
import AutomaticApprovalAlert from './Components/AutomaticApprovalAlert';
import { GlobalStateActions } from '@store/globalState';

const SelfRegistration = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const defaultData = useAppSelector(state => state.settings.defaultSelfRegistration);
  const selfRegistration = useAppSelector(SettingsSelectors.getSelfRegistration);
  const selfRegistrationFetching = useAppSelector(state => state.settings.selfRegistrationFetching);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const settingsSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );
  const customError = useAppSelector(state => state.settings.selfRegistrationCustomError);

  const [passwordIsError, setPasswordIsError] = useState(false);
  const [euShareIsError, setEuShareIsError] = useState(false);
  const [registrationDeadlineIsError, setRegistrationDeadlineIsError] = useState(false);
  const [open, setOpen] = useState(false);
  const [linkCustomizationIsOpen, setLinkCustomizationIsOpen] = useState(false);
  const [qrCodeIsOpen, setQrCodeIsOpen] = useState(false);
  const [publishSignup, setPublishSignup] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [originPublished, setOriginPublished] = useState(selfRegistration.isUrlPublished);
  const [firstSwitch, setFirstSwitch] = useState(true);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [needsToFillAL, setNeedsToFillAL] = useState(false);
  const [isShowApprovalAlert, setIsShowApprovalAlert] = useState(false);
  const [registrationDeadlineSwitcherValue, setRegistrationDeadlineSwitcherValue] = useState(false);
  const generalSettingsRef = useRef<HTMLSpanElement | null>(null);
  const isPasswordUnchangable = !selfRegistration.isPasswordChangeable;

  const [domains, setDomains] = useState<string>('');
  const euShare = useMemo(
    () => (selfRegistration.euShare !== undefined ? selfRegistration.euShare : ''),
    [selfRegistration.euShare],
  );

  const prepareDomainList = (receivedList: string) =>
    receivedList.length ? receivedList.split(' ').filter(el => el !== '') : [];
  const [domainList, setDomainList] = useState<string[]>(
    prepareDomainList(selfRegistration.validEmailDomains),
  );

  const deleteDomain = (domainIndex: number) => {
    const updDomainList = domainList.filter((_, index: number) => index !== domainIndex);
    setDomainList(updDomainList);
  };

  const updateTagList = (event: any) => {
    if (
      (event.code === 'Enter' || event.code === 'Space') &&
      event.target.value.trim().length > 0
    ) {
      const inputValue = event.target.value;

      setDomainList((prev: any) => [...prev, inputValue]);
      setDomains('');
    }
  };

  useEffect(() => {
    if (selfRegistrationFetching === FetchingStatus.FULFILLED) {
      setRegistrationDeadlineSwitcherValue(!!defaultData.registrationDeadlineSwitcher);
      dispatch(GlobalStateActions.setRegistrationDeadlinePopupState(false));
    }
  }, [defaultData.registrationDeadlineSwitcher, selfRegistrationFetching, dispatch]);

  useEffect(() => {
    if (selfRegistration) {
      setPublishSignup(selfRegistration.isUrlPublished);

      if (firstSwitch && selfRegistration.isUrlPublished) setOriginPublished(true);
      if (selfRegistration.validEmailDomains)
        setDomainList(prepareDomainList(selfRegistration.validEmailDomains));
    }
  }, [selfRegistration, firstSwitch]);

  const goToCustomizeForm = () => navigate(`/${language}/settings/customise-form`);

  const qrCodeToggler = () => setQrCodeIsOpen(!qrCodeIsOpen);
  const saveCustomizationLink = () => {
    dispatch(
      SettingsActions.setRegistrationIdentifier({ signupID: selfRegistration.signupIdentifier }),
    );
    closeLinkCustomizationToggler();
  };
  const closeLinkCustomizationToggler = () => setLinkCustomizationIsOpen(false);
  const openLinkCustomizationToggler = () => {
    dispatch(
      SettingsActions.selfRegistration({
        key: 'signupIdentifier',
        value: defaultData.signupIdentifier,
      }),
    );
    setLinkCustomizationIsOpen(true);
  };

  const handleTextbox = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name as keyof ISelfRegistration;
    let value = event.target.value;
    switch (key) {
      case 'validEmailDomains': {
        if (value.trim().length > 0 && value.trim().includes(' ')) {
          setDomainList(prev => [...prev, ...value.split(' ')]);
          setDomains('');
        } else {
          setDomains(value);
        }
        value = domainList.join(' ');
        dispatch(
          SettingsActions.selfRegistration({
            key: 'validEmailDomains',
            value: domainList.join(' '),
          }),
        );
        break;
      }
      case 'euShare': {
        if (euShareIsError) {
          setEuShareIsError(!euShareRegExp.test(value));
        }

        break;
      }
      case 'signupPassword': {
        if (passwordIsError) {
          setPasswordIsError(!signupPasswordRegExp.test(value));
        }
        break;
      }
      case 'registrationDeadline': {
        dispatch(GlobalStateActions.setRegistrationDeadlinePopupState(false));
        dispatch(SettingsActions.setSelfRegistrationCustomError(null));
        if (registrationDeadlineIsError) {
          setRegistrationDeadlineIsError(false);
        }
      }
    }
    dispatch(
      SettingsActions.selfRegistration({
        key,
        value,
      }),
    );
  };

  const tickboxChange = (key: string, value: boolean) => {
    if (needsToFillAL && value) {
      setShowSettingsModal(true);
      return false;
    }
    if (key === 'isUrlPublished') {
      if (firstSwitch) {
        setFirstSwitch(false);
      }
      if (originPublished) {
        !value ? setShowHint(true) : setShowHint(false);
      }
    }

    if (key === 'autoConfirmSignup' && !value) {
      toggleApprovalModal();
    }

    if (key === 'registrationDeadlineSwitcher') {
      setRegistrationDeadlineSwitcherValue(value);
      //set up guard state with current value, because we set default value as empty string
      dispatch(GlobalStateActions.setRegistrationDeadlinePopupState(value));
      dispatch(SettingsActions.selfRegistration({ key: 'registrationDeadline', value: '' }));
      if (!value) {
        setRegistrationDeadlineIsError(false);
        dispatch(SettingsActions.setSelfRegistrationCustomError(null));
      }
    }

    dispatch(SettingsActions.selfRegistration({ key: key as keyof ISelfRegistration, value }));
  };

  useEffect(() => {
    dispatch(
      GlobalStateActions.setSelfRegistrationChangesPopupState(
        !isEqual(defaultData, selfRegistration),
      ),
    );
  }, [dispatch, defaultData, selfRegistration]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_SIGNUP_URL}${defaultData.signupIdentifier}`,
    );
  };

  useEffect(() => {
    dispatch(SettingsActions.getRegistrationUsers({}));
    if (activeCompany) {
      const { companyId } = activeCompany;

      dispatch(SettingsActions.getSettings({ companyId }));
      dispatch(
        SettingsActions.getDistributionList({ companyId, listType: ListType.ACTIVATION_LETTER }),
      );
    }
  }, [activeCompany, dispatch]);

  useEffect(() => {
    if (
      settingsSettings &&
      activationLetterDistributionListFetchingStatus === FetchingStatus.FULFILLED
    ) {
      setNeedsToFillAL(
        settingsSettings.activationLetterRecipientMode.toLowerCase() === 'cu' &&
          activationLetterDistributionList.length === 0,
      );
    }
  }, [
    activationLetterDistributionList,
    activationLetterDistributionListFetchingStatus,
    settingsSettings,
    settingsSettings.activationLetterRecipientMode,
  ]);

  const doSave = async () => {
    dispatch(
      SettingsActions.selfRegistration({
        key: 'validEmailDomains',
        value: domainList.join(' '),
      }),
    );
    let formHasErrors = false;

    if (
      selfRegistration.passwordRequired &&
      !signupPasswordRegExp.test(selfRegistration.signupPassword)
    ) {
      setPasswordIsError(true);
      formHasErrors = true;
    }
    if (!isDirectPay && selfRegistration.euShare && !euShareRegExp.test(selfRegistration.euShare)) {
      setEuShareIsError(true);
      formHasErrors = true;
    }
    if (registrationDeadlineSwitcherValue && !selfRegistration.registrationDeadline) {
      setRegistrationDeadlineIsError(true);
      formHasErrors = true;
    }
    if (formHasErrors) {
      return;
    }
    await dispatch(SettingsActions.setRegistrationSettings({}));

    setOriginPublished(selfRegistration.isUrlPublished);
    if (selfRegistration.isUrlPublished) setFirstSwitch(true);
  };

  const resetAll = () => {
    dispatch(SettingsActions.resetSelfRegistrationToDefault());
    dispatch(GlobalStateActions.setRegistrationDeadlinePopupState(false));
    setRegistrationDeadlineSwitcherValue(!!defaultData.registrationDeadlineSwitcher);
    setDomainList([]);
  };

  const isDirectPay = selfRegistration.directPay;
  const publishedHint = useMemo(
    () => (
      <ModalError
        isInfo
        isErrorTitle={false}
        descriptionErrorMessage={
          isDirectPay
            ? intl.formatMessage({
                id: 'user_setting.self_registration.visibility_settings.disabled_hint',
                defaultMessage: 'Users with direct pay cannot change this setting.',
              })
            : intl.formatMessage({
                id: 'user_setting.self_registration.visibility_settings.off_hint',
                defaultMessage:
                  'After unpublishing signup page new employees will no longer be able to sign up by themselves',
              })
        }
        errorContainerClassName='infoContainer'
      />
    ),
    [isDirectPay, intl],
  );

  const downloadQR = () => {
    const canvas: any = document.querySelector('.qrCodeImg > canvas');
    const link = document.createElement('a');
    link.href = canvas.toDataURL();
    link.download = 'QR.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isLoading = useMemo(
    () =>
      [
        activationLetterDistributionListFetchingStatus,
        settingsSettings.fetchingStatus,
        selfRegistrationFetching,
      ].includes(FetchingStatus.PENDING),
    [activationLetterDistributionListFetchingStatus, settingsSettings, selfRegistrationFetching],
  );

  const toggleApprovalModal = useCallback(
    () => setIsShowApprovalAlert(prev => !prev),
    [setIsShowApprovalAlert],
  );

  const scrollTogeneralSettings = useCallback(() => {
    if (!generalSettingsRef.current) {
      return;
    }
    const yCoordinate = window.scrollY + generalSettingsRef.current.getBoundingClientRect().top;
    const yOffset = -80; // 72px header + 8px small top margin
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }, [generalSettingsRef]);

  useEffect(() => {
    if (customError && customError['registrationDeadline'] === 'guardError') {
      scrollTogeneralSettings();
      setRegistrationDeadlineIsError(
        registrationDeadlineSwitcherValue && !selfRegistration.registrationDeadline,
      );
    }
  }, [
    customError,
    registrationDeadlineSwitcherValue,
    scrollTogeneralSettings,
    selfRegistration.registrationDeadline,
  ]);

  return (
    <SelfRegistrationWrapper id='SelfRegistrationWrapper'>
      <Loader inCard show={isLoading} portalId='SelfRegistrationWrapper' />
      {needsToFillAL && (
        <GoToSettings isOpen={showSettingsModal} handleClose={() => setShowSettingsModal(false)} />
      )}
      <Typography variant='h6'>
        {intl.formatMessage({
          id: 'user_setting.self_registration.title',
          defaultMessage: 'Employee self-registration',
        })}
      </Typography>
      <Spacer size='md' />
      <Typography variant='subtitle1'>
        {intl.formatMessage({
          id: 'user_setting.self_registration.subtitle',
          defaultMessage: 'Let employees sign in by themself using self-registration page.',
        })}
      </Typography>
      <Spacer size='2xl' />
      <Box className='self-registration-wrapper'>
        <>
          <Typography variant='h3'>
            {intl.formatMessage({
              id: 'user_setting.self_registration.visibility_settings.title',
              defaultMessage: 'Visibility settings',
            })}
          </Typography>
          <Spacer size='md' />
          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'user_setting.self_registration.visibility_settings.subtitle',
              defaultMessage:
                'Create custom self-registration page according to the corporate identity.',
            })}
          </Typography>
          <Spacer size='lg' />
          <Tickbox
            label={
              publishSignup
                ? intl.formatMessage({
                    id: 'user_setting.signup_page.publish',
                    defaultMessage: 'Signup page is published',
                  })
                : intl.formatMessage({
                    id: 'user_setting.signup_page.unpublish',
                    defaultMessage: 'Signup page is unpublished',
                  })
            }
            value={publishSignup}
            disabled={isDirectPay}
            fieldName='isUrlPublished'
            handleChange={tickboxChange}
          />
          <Spacer size='sm' />
          {isDirectPay && publishedHint}
          {originPublished && showHint ? publishedHint : ''}
          {!isDirectPay && (
            <>
              <Spacer size='sm' />
              <Typography variant='subtitle1'>
                {intl.formatMessage({
                  id: 'user_setting.signup_page.employee_share.description',
                  defaultMessage:
                    'Enter the employee share of the Hansefit price so that it is displayed on the sign-up page.',
                })}
              </Typography>
              <Spacer size='md' />
              <DefaultTextField
                name='euShare'
                value={euShare.toString().replace('.', ',')}
                width={330}
                label={intl.formatMessage({
                  id: 'user_setting.signup_page.employee_share',
                  defaultMessage: 'Employee share',
                })}
                onChange={handleTextbox}
                error={euShareIsError}
              />
              {euShareIsError && (
                <Typography variant='subtitle2' fontSize='14px' color={theme.palette.error.main}>
                  {intl.formatMessage({
                    id: 'user_setting.self_registration.invitation_settings.euShareValidation',
                    defaultMessage: 'Value must be a positive number',
                  })}
                </Typography>
              )}
            </>
          )}
          <Spacer size='xl' />
          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'user_setting.self_registration.invitation_settings.qrCodeDescription',
              defaultMessage: 'Share link or QR code with everyone in your company',
            })}
          </Typography>
          <Spacer size='sm' />
          <Stack direction='row' spacing={2}>
            <Box className='share__link_wrapper'>
              <Typography variant='body1'>{`${process.env.REACT_APP_SIGNUP_URL}${defaultData.signupIdentifier}`}</Typography>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={intl.formatMessage({
                      id: 'user_setting.self_registration.invitation_settings.copiedTooltip',
                      defaultMessage: 'Link was copied',
                    })}>
                    <Button variant='text' startIcon={<CopyIcon />} onClick={handleTooltipOpen}>
                      {intl.formatMessage({
                        id: 'user_setting.self_registration.invitation_settings.copyButton',
                        defaultMessage: 'Copy',
                      })}
                    </Button>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Box>
            <Stack direction='row' spacing={1}>
              <Button
                className='share__link_generate'
                variant='contained'
                onClick={openLinkCustomizationToggler}>
                {intl.formatMessage({
                  id: 'user_setting.self_registration.invitation_settings.customizeButton',
                  defaultMessage: 'Customize link',
                })}
              </Button>
            </Stack>
          </Stack>
          <Spacer size='xl' />
          <CCPDefaultButton variant='outlined' onClick={qrCodeToggler}>
            {intl.formatMessage({
              id: 'user_setting.self_registration.invitation_settings.qrCodeButton',
              defaultMessage: 'Get QR code',
            })}
          </CCPDefaultButton>
        </>
        <>
          <Divider />
          <Typography variant='h3' ref={generalSettingsRef}>
            {intl.formatMessage({
              id: 'user_setting.self_registration.general_settings.title',
              defaultMessage: 'General settings',
            })}
          </Typography>
          <Spacer size='lg' />
          <Tickbox
            label={intl.formatMessage({
              id: 'user_setting.self_registration.general_settings.autoConfirmSignup',
              defaultMessage: 'Automatic approval of all requests',
            })}
            value={selfRegistration.autoConfirmSignup}
            fieldName='autoConfirmSignup'
            handleChange={tickboxChange}
          />
          <Spacer size='sm' />
          {!selfRegistration.autoConfirmSignup && (
            <ModalError
              isInfo
              isErrorTitle={false}
              descriptionErrorMessage={intl.formatMessage({
                id: 'user_setting.self_registration.publishing.error_description',
                defaultMessage:
                  'Please remember to check new requests regularly, as manually activating employee requests may otherwise lead to delays in the start of training for your employees.',
              })}
              errorContainerClassName='infoContainer'
            />
          )}
          <Spacer size='md' />
          <Tickbox
            label={intl.formatMessage({
              id: 'user_setting.self_registration.general_settings.deadline',
              defaultMessage: 'Deadline for registrations for the upcoming month',
            })}
            value={registrationDeadlineSwitcherValue}
            fieldName='registrationDeadlineSwitcher'
            handleChange={tickboxChange}
          />
          {registrationDeadlineSwitcherValue && (
            <>
              <Spacer size='md' />
              <DefaultTextField
                select
                width={330}
                name='registrationDeadline'
                value={selfRegistration.registrationDeadline}
                error={registrationDeadlineIsError}
                onChange={handleTextbox}>
                {['10', '15', '20', '25'].map(item => (
                  <MenuItem style={{ minHeight: '48px' }} key={item} value={item}>
                    {`${item}th`}
                  </MenuItem>
                ))}
              </DefaultTextField>
              {registrationDeadlineIsError && (
                <Typography variant='subtitle2' fontSize='14px' color={theme.palette.error.main}>
                  {intl.formatMessage({
                    id: 'user_setting.self_registration.deadline_settings.deadline_validation',
                    defaultMessage: 'Please select a date',
                  })}
                </Typography>
              )}
            </>
          )}
          <Spacer size='md' />
          {isDirectPay && (
            <>
              <Spacer size='md' />
              <Tickbox
                label={intl.formatMessage({
                  id: 'user_setting.self_registration.general_settings.allowSEPA',
                  defaultMessage: 'Allow SEPA direct payment',
                })}
                value={selfRegistration.directPay}
                fieldName='allowSEPA'
                disabled={isDirectPay}
                handleChange={tickboxChange}
              />
            </>
          )}
        </>
        <Divider />
        <Typography variant='h3'>
          {intl.formatMessage({
            id: 'user_setting.self_registration.customize_settings.title',
            defaultMessage: 'Registration page settings',
          })}
        </Typography>
        <Spacer size='md' />
        <Typography variant='subtitle1'>
          {intl.formatMessage({
            id: 'user_setting.self_registration.customize_settings.subtitle',
            defaultMessage:
              'Create custom self-registration page accordingly to the corporate identity.',
          })}
        </Typography>
        <Spacer size='lg' />
        <CCPDefaultButton variant='contained' onClick={goToCustomizeForm}>
          {intl.formatMessage({
            id: 'user_setting.self_registration.customize_settings.customize',
            defaultMessage: 'Customise',
          })}
        </CCPDefaultButton>
        <Divider />
        <Typography variant='h3'>
          {intl.formatMessage({
            id: 'user_setting.self_registration.invitation_settings.title',
            defaultMessage: 'Invitation settings',
          })}
        </Typography>
        <Spacer size='md' />
        <Typography variant='subtitle1'>
          {intl.formatMessage({
            id: 'user_setting.self_registration.invitation_settings.subtitle',
            defaultMessage: '[Description text]',
          })}
        </Typography>
        <Spacer size='xl' />
        <Tickbox
          label={intl.formatMessage({
            id: 'user_setting.self_registration.invitation_settings.passwordRequired',
            defaultMessage: 'Add password protection',
          })}
          value={selfRegistration.passwordRequired}
          fieldName='passwordRequired'
          handleChange={tickboxChange}
        />
        {selfRegistration.passwordRequired && (
          <>
            <Spacer size='sm' />
            <Typography variant='subtitle1'>
              {intl.formatMessage({
                id: 'user_setting.self_registration.invitation_settings.passwordDescription',
                defaultMessage:
                  'Assign a password for the signup page to prevent unauthorised access.',
              })}
            </Typography>
            <Spacer size='md' />
            <Stack direction='column' spacing={1}>
              <DefaultTextField
                value={selfRegistration.signupPassword}
                width={330}
                error={passwordIsError && selfRegistration.signupPassword.length < 10}
                label={intl.formatMessage({
                  id: 'user_setting.self_registration.invitation_settings.passwordLabel',
                  defaultMessage: 'Password',
                })}
                name='signupPassword'
                disabled={isPasswordUnchangable}
                onChange={handleTextbox}
              />
              {passwordIsError && (
                <Typography variant='subtitle2' fontSize='14px' color={theme.palette.error.main}>
                  {intl.formatMessage({
                    id: 'user_setting.self_registration.invitation_settings.passwordValidation',
                    defaultMessage:
                      'Passwords must be no more than 10 characters in length. Use letters or/and numbers.',
                  })}
                </Typography>
              )}
            </Stack>
            <Spacer size='lg' />
          </>
        )}
        <Spacer size='lg' />
        <Tickbox
          label={intl.formatMessage({
            id: 'user_setting.self_registration.invitation_settings.limitationEmailDomains',
            defaultMessage: 'Limitation of e-mail domains for the signup page',
          })}
          value={selfRegistration.domainValidation}
          fieldName='domainValidation'
          handleChange={tickboxChange}
        />
        {selfRegistration.domainValidation && (
          <>
            <Spacer size='lg' />
            <Typography variant='subtitle1'>
              {intl.formatMessage({
                id: 'user_setting.self_registration.invitation_settings.limitationDomainsDescription',
                defaultMessage:
                  'Fill in the field with all domains to filter the emails that are accepted on the signup page.',
              })}
            </Typography>
            <Spacer size='lg' />
            <Stack direction='row' spacing={4} alignItems='center'>
              <DefaultTextField
                value={domains}
                label={intl.formatMessage({
                  id: 'user_setting.self_registration.invitation_settings.emailDomainsLabel',
                  defaultMessage: 'Domain',
                })}
                name='validEmailDomains'
                placeholder={intl.formatMessage({
                  id: 'user_setting.self_registration.invitation_settings.domainValidation.placeholder',
                  defaultMessage: 'Enter domain names',
                })}
                className='domains-wrapper'
                onChange={handleTextbox}
                onKeyDown={updateTagList}
                InputProps={{
                  startAdornment: domainList.map((tag: string, i: number) => (
                    <InputTag removeTag={deleteDomain} key={`${tag}-${i}`} input={tag} index={i} />
                  )),
                }}
              />
            </Stack>
            <Spacer size='sm' />
            <Typography variant='subtitle2' fontSize='14px'>
              {intl.formatMessage({
                id: 'user_setting.self_registration.invitation_settings.domainValidation',
                defaultMessage: 'Enter the domains here and separate several with a comma.',
              })}
            </Typography>
          </>
        )}
        <Divider />
        <Stack direction='row' spacing={3} justifyContent='flex-end'>
          <CCPDefaultButton variant='outlined' onClick={resetAll}>
            {intl.formatMessage({
              id: 'user_setting.self_registration.reset',
              defaultMessage: 'Reset all',
            })}
          </CCPDefaultButton>
          <CCPDefaultButton variant='contained' onClick={doSave}>
            {intl.formatMessage({
              id: 'user_setting.self_registration.apply',
              defaultMessage: 'Apply & save',
            })}
          </CCPDefaultButton>
        </Stack>
      </Box>
      {linkCustomizationIsOpen && (
        <PromptModal
          title={intl.formatMessage({
            id: 'user_setting.self_registration.linkCustomization',
            defaultMessage: 'Link Customization',
          })}
          subtitle={intl.formatMessage({
            id: 'user_setting.self_registration.linkCustomizationDescription',
            defaultMessage: 'Customize link to describe your company',
          })}
          mainContent={
            <>
              <Spacer size='md' />
              <Typography variant='subtitle1'>
                {intl.formatMessage({
                  id: 'user_setting.self_registration.linkCustomizationPreview',
                  defaultMessage: 'Preview:',
                })}
              </Typography>
              <Spacer size='xs' />
              <Typography variant='subtitle2'>
                {process.env.REACT_APP_SIGNUP_URL}
                <b style={{ color: theme.palette.neutralDark.contrastText }}>
                  {selfRegistration.signupIdentifier}
                </b>
              </Typography>
              <Spacer size='md' />
              <DefaultTextField
                name='signupIdentifier'
                value={selfRegistration.signupIdentifier}
                width='100%'
                required
                label={intl.formatMessage({
                  id: 'user_setting.self_registration.invitation_settings.identifierLabel',
                  defaultMessage: 'Company identifier',
                })}
                error={
                  selfRegistration.signupIdentifier.length === 0 &&
                  selfRegistration.signupIdentifier.match(/^\w+$/) === null
                }
                onChange={handleTextbox}
              />
              <Spacer size='2xl' />
              <ValidationMessage>
                <ErrorOutlineIcon style={{ color: theme.palette.warning.main }} />
                <Typography variant='subtitle1' fontSize={14}>
                  {intl.formatMessage({
                    id: 'user_setting.self_registration.cta.validation_message',
                    defaultMessage:
                      '[Description text, with a warning that this may leed to more false signups].',
                  })}
                </Typography>
              </ValidationMessage>
            </>
          }
          isValid={
            selfRegistration.signupIdentifier.length > 0 &&
            selfRegistration.signupIdentifier.match(/^[a-zA-Z0-9_-]+$/) !== null
          }
          okText={intl.formatMessage({
            id: 'user_setting.self_registration.cta.confirm',
            defaultMessage: 'Confirm & generate link',
          })}
          isOpen={linkCustomizationIsOpen}
          closeModal={closeLinkCustomizationToggler}
          handleSubmit={saveCustomizationLink}
        />
      )}
      {qrCodeIsOpen && (
        <AlertModal
          showIcon={false}
          title={intl.formatMessage({
            id: 'user_setting.self_registration.qrCode',
            defaultMessage: 'QR Code',
          })}
          description={intl.formatMessage({
            id: 'user_setting.self_registration.qrCodeDescription',
            defaultMessage: 'This code it is a link to your company signup page',
          })}
          buttonText={intl.formatMessage({
            id: 'user_setting.self_registration.qrCode.modal.cta.back',
            defaultMessage: 'Go to settings',
          })}
          mainContent={
            <Box display='flex' justifyContent='center' className='qrCodeImg'>
              <QRCodeCanvas
                value={`${process.env.REACT_APP_SIGNUP_URL}${defaultData.signupIdentifier}`}
                size={256}
              />
            </Box>
          }
          isOpen={qrCodeIsOpen}
          handleClose={qrCodeToggler}
          handleConfirm={downloadQR}
          showConfirmButton
          confirmButtonText={intl.formatMessage({
            id: 'user_setting.self_registration.qrCode.modal.cta.download',
            defaultMessage: 'Download',
          })}
        />
      )}
      {isShowApprovalAlert && <AutomaticApprovalAlert handleClose={toggleApprovalModal} />}
    </SelfRegistrationWrapper>
  );
};

export default SelfRegistration;
