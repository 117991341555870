import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useIntl } from 'react-intl';
import { CCPDefaultButton } from 'components';
import theme from '@theme/theme';
import { useAppDispatch } from '@store/index';
import { SettingsActions } from '@features/settings/store';

type GuardRegistrationDeadlineProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

const GuardSelfRegistrationChanges = ({ onCancel, onConfirm }: GuardRegistrationDeadlineProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const onProceed = () => {
    //we need to reset all data to default
    dispatch(SettingsActions.resetSelfRegistrationToDefault());
    onConfirm();
  };

  return (
    <Dialog open onClose={onCancel}>
      <CloseIcon onClick={onCancel} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>
          {formatMessage({
            id: 'router.leaving_signup.changes_dialog.title',
            defaultMessage: 'Your changes haven’t been saved',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          {formatMessage({
            id: 'router.leaving_signup.changes_dialog.subtitle',
            defaultMessage: `Changes have not been saved. Are you sure you want to leave this page without saving your settings?.`,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={onCancel} variant='outlined'>
          {formatMessage({ id: 'router.leaving.cta.cancel', defaultMessage: 'Stay on the page' })}
        </CCPDefaultButton>
        <CCPDefaultButton onClick={onProceed} variant='contained'>
          {formatMessage({
            id: 'router.leaving_signup.changes_cta.confirm',
            defaultMessage: 'Proceed',
          })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default GuardSelfRegistrationChanges;
