import { StatsTableProps } from '@localTypes/stats';
import CustomTable from '@components/Table';
import TableLoader from '@features/stats/components/TableLoader';
import TableErrorData from '@features/stats/components/TableErrorData';
import TableEmptyData from '@features/stats/components/TableEmptyData';
import ActiveMembersRow from './ActiveMembersRow';
import CheckInsRow from './CheckInsRow';
import CouponsRow from './CouponsRow';
import StatsTableWrapper from './StatsTableWrapper.styles';
import { isActiveMembersRow, isCheckInsRow, isCouponRow } from '@utils/getStatsTypes';

type StatsTableCustomProps = {
  isLoading: boolean;
  hasError: boolean;
  statsList: Array<StatsTableProps>;
  tableColumns: Array<{
    intlId?: string;
    columnName: string;
    columnTitle: string;
  }>;
};

const StatsTable = ({ isLoading, hasError, statsList, tableColumns }: StatsTableCustomProps) => {
  const renderRow = (item: StatsTableProps) => {
    if (isActiveMembersRow(item)) {
      return (
        <ActiveMembersRow
          customer_id={item.customer_id}
          first_name={item.first_name}
          last_name={item.last_name}
          registration_date={item.registration_date}
          status={item.status}
        />
      );
    }
    if (isCheckInsRow(item)) {
      return (
        <CheckInsRow
          partner_name={item.partner_name}
          eus={item.eus}
          checkins={item.checkins}
          partner_id={item.partner_id}
        />
      );
    }
    if (isCouponRow(item)) {
      return (
        <CouponsRow
          partner_name={item.partner_name}
          eus={item.eus}
          coupons={item.coupons}
          partner_id={item.partner_id}
        />
      );
    }
    return null;
  };
  return (
    <StatsTableWrapper>
      {isLoading && <TableLoader />}
      {hasError && <TableErrorData />}
      {!isLoading && !hasError && (
        <>
          <CustomTable
            columnsList={tableColumns}
            renderRow={renderRow}
            listData={statsList}
            isWhiteBackground
            showHeaderOnEmpty
          />
          {statsList.length === 0 && <TableEmptyData />}
        </>
      )}
    </StatsTableWrapper>
  );
};

export default StatsTable;
