import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FetchingStatus, FiltersProps } from 'types';
import { StatsActions } from 'features/stats/store';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';

import CustomStatsWrapper from '../CustomStatsWrapper.styles';
import { getFirstDayOfMonth, getLastDayOfMonth } from '@utils/getStatsMonth';
import StatsInfo from '@features/stats/components/StatsInfo';
import StatsTable from '@features/stats/components/StatsTable';

const activeMembersColumns = [
  {
    intlId: 'view.stats.table.id',
    columnName: 'id',
    columnTitle: 'ID',
  },
  {
    intlId: 'view.stats.table.member_name',
    columnName: 'name',
    columnTitle: 'Member Full name',
  },
  {
    intlId: 'view.stats.table.registration_date',
    columnName: 'regDate',
    columnTitle: 'Registration date',
  },
];

const ActiveMembersPage = () => {
  const dispatch = useAppDispatch();
  const { activeMembers } = useAppSelector(state => state.stats);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const [filters, setFilters] = useState<FiltersProps>({
    startMonth: new Date().getMonth(),
    endMonth: new Date().getMonth(),
    startYear: new Date().getFullYear(),
    endYear: new Date().getFullYear(),
  });

  const activeMembersCount = useMemo(() => activeMembers.data?.length || 0, [activeMembers?.data]);

  const getActiveMembersList = useCallback(async () => {
    const { startMonth, endMonth, startYear, endYear } = filters;

    if (!activeCompany?.companyId) return;

    dispatch(
      StatsActions.getActiveMembers({
        companyId: activeCompany?.companyId,
        startMonth: getFirstDayOfMonth(startYear, startMonth),
        endMonth: getLastDayOfMonth(endYear, endMonth),
      }),
    );
  }, [activeCompany?.companyId, filters, dispatch]);

  const updateFiltersHandler = (filters?: FiltersProps): void => {
    if (filters) setFilters(filters);
  };

  useEffect(() => {
    getActiveMembersList();
  }, [getActiveMembersList]);

  const hasError = useMemo(
    () => activeMembers.fetchingStatus === FetchingStatus.REJECTED,
    [activeMembers.fetchingStatus],
  );
  const isLoading = useMemo(
    () => activeMembers.fetchingStatus === FetchingStatus.PENDING,
    [activeMembers.fetchingStatus],
  );

  const activeMembersList = useMemo(() => activeMembers.data || [], [activeMembers.data]);

  return (
    <CustomStatsWrapper>
      <Box className='container'>
        <StatsInfo
          data={activeMembersList}
          count={activeMembersCount}
          filters={filters}
          fileName='active-members'
          className='green'
          isLoading={isLoading}
          updateFilters={updateFiltersHandler}
          hasError={hasError}
        />
        <StatsTable
          isLoading={isLoading}
          hasError={hasError}
          tableColumns={activeMembersColumns}
          statsList={activeMembersList}
        />
      </Box>
    </CustomStatsWrapper>
  );
};

export default ActiveMembersPage;
