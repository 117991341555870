import { useMemo } from 'react';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useAppSelector } from '@store/index';
import { useTranslationLanguage } from './useTranslationLanguage';

export interface UseTableRowsProps {
  uniqueIdKey: string;
  customFieldKey?: string;
  globalCustomFieldKey?: string;
  data: GridValidRowModel[];
}

export const useTableRows = ({
  uniqueIdKey,
  customFieldKey,
  globalCustomFieldKey,
  data = [],
}: UseTableRowsProps) => {
  const translationKey = useTranslationLanguage();
  const countries = useAppSelector(state => state.company.countriesList.data);

  const memoizedCountries = useMemo(() => countries || [], [countries]);

  const resultRows = useMemo(() => {
    const rows = [] as GridValidRowModel[];

    data.forEach(entry => {
      const keys = Object.keys(entry);
      const rowEntry: GridRowModel = {};

      keys.forEach(key => {
        if (key === uniqueIdKey) {
          rowEntry['id'] = entry[key] as number;
          rowEntry[key] = entry[key] as string | number;
        } else if (key === customFieldKey) {
          const customFieldKeys = Object.keys(entry[key]);
          customFieldKeys.forEach(customKey => {
            rowEntry[customKey] = entry[key as string][customKey as string];
          });
        } else if (key === globalCustomFieldKey) {
          const customFieldKeys = Object.keys(entry[key]);
          customFieldKeys.forEach(customKey => {
            rowEntry[customKey] =
              customKey === 'hansefit_country'
                ? memoizedCountries.filter(country => country.code === entry[key][customKey])[0]
                    ?.name[translationKey]
                : entry[key as string][customKey as string];
          });
        } else {
          rowEntry[key] = entry[key] as string | number;
        }
      });

      return rows.push(rowEntry);
    });

    return rows;
  }, [data, uniqueIdKey, customFieldKey, globalCustomFieldKey, memoizedCountries, translationKey]);

  return resultRows;
};
