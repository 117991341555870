import { Box, Typography } from '@mui/material';
import { Spacer } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeDetailsStyled } from '../EmployeeDetails/EmployeeDetails.styles';
import { BasicEmployee, TranslationsKeys } from 'types';
import { CompanyActions } from 'features/company/store';
import getCustomFieldEnumLabel from '@utils/getCustomFieldEnumLabel';

export const CustomFields = ({ data }: { data: BasicEmployee['customFields'] }) => {
  const [cookies] = useCookies(['language_app']);
  const activeLanguage: TranslationsKeys = cookies.language_app || 'de';
  const company = useAppSelector(CompanySelectors.getApi);
  const companyData = company.getCompany.data;
  const companyDefaultLanguage =
    (companyData?.communicationLanguage.toLocaleLowerCase() as TranslationsKeys) || 'de';

  const companyCustomFields = companyData?.customFields;
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeCompany) {
      dispatch(CompanyActions.getCompany({ companyId: activeCompany?.companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCustomFields = (fields: BasicEmployee['customFields']) => {
    const customFieldList: JSX.Element[] = [];

    if (companyCustomFields) {
      companyCustomFields.forEach(compField => {
        customFieldList.push(
          <Box key={compField.key}>
            <Typography variant='body2' className='employeeInfoHeader'>
              {compField.name[activeLanguage] ||
                compField.name['en'] ||
                compField.name[companyDefaultLanguage]}
            </Typography>
            <Spacer height={16} />
            <Typography variant='body2' className='infoData'>
              {getCustomFieldEnumLabel(
                fields[compField.key]?.toString(),
                compField,
                activeLanguage,
                companyDefaultLanguage,
              )}
            </Typography>
            <Spacer height={48} />
          </Box>,
        );
      });
    }

    return customFieldList;
  };

  return <EmployeeDetailsStyled>{data && <>{renderCustomFields(data)}</>}</EmployeeDetailsStyled>;
};
