export const globalCustomFieldsSort = [
  ['hansefit_street'],
  ['hansefit_zip', 'hansefit_city'],
  ['hansefit_country'],
  ['hansefit_location'],
  ['hansefit_division'],
  ['hansefit_personnel_number'],
];

export const addressGlobalCustomFieldsSort = ['hansefit_street', 'hansefit_zip', 'hansefit_city'];
