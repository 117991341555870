import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AddEmployeeRequestBody, Employee, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';

export const addEmployee = createAsyncThunk<
  Employee,
  AddEmployeeRequestBody,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/addEmployee', async (body, { rejectWithValue }) => {
  const axios = await axiosWithConfig();
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/endusers`;

  try {
    return (await axios.post(endpoint, body)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
