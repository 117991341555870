import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useAppDispatch, useAppSelector } from 'store/store';
import { UserManagementActions } from 'features/userManagement/store';
import { AuthenticationActions } from 'features/authentication/store';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import {
  CompaniesNamesResponse,
  CompanyRole,
  DeleteUserModalProps,
  FetchingStatus,
  IUserOverview,
  ListEntries,
  ListType,
} from 'types';

import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { useIntl } from 'react-intl';
import { CCPDefaultButton } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { axiosWithConfig } from 'api';
import { showToast } from 'utils';
import Loader from 'components/Loader';
import { CompanyActions } from 'features/company/store';
import { default as theme } from '@theme/theme';
import { SettingsSelectors } from '@features/settings/store/settings.selectors';
import { SettingsActions } from '@features/settings/store';
import getDeleteUserModalContent from '@utils/getDeleteUserModalContent';

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ isOpen, email, closeModal }) => {
  const intl = useIntl();
  const { language } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(state => state.userManagement.users);
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const { companiesNames } = useAppSelector(CompanySelectors.getCompanyState);
  const [showLoading, setShowLoading] = useState<boolean>(companiesNames.data.length > 1);
  const [isDeleteUser, setIsDeleteUser] = useState<boolean>(true);
  const [companyWithLastAdmin, setCompanyWithLastAdmin] = useState<CompaniesNamesResponse | null>(
    null,
  );
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );
  const [isLastLetterRecipient, setIsLastLetterRecipient] = useState<boolean>(false);

  const isSingleCompany = companiesNames.data.length === 1;
  const checkIfLastRecipient = useCallback(
    () =>
      activationLetterRecipientMode.toLowerCase() === 'cu' &&
      activationLetterDistributionList.length === 1 &&
      activationLetterDistributionList[0] === userInfo?.email,
    [activationLetterDistributionList, activationLetterRecipientMode, userInfo?.email],
  );
  const checkLastAdmin = (user: IUserOverview) =>
    user.status === 'Active' && user.role.includes(CompanyRole.Admin);

  const getUsersForAllCompanies = useCallback(async () => {
    setShowLoading(true);

    const axios = await axiosWithConfig();

    try {
      const resp: {
        value: any;
        status: 'fulfilled' | 'rejected';
      }[] = (
        await Promise.allSettled([
          ...companiesNames.data.map(({ companyId }) =>
            axios.get(`${process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT}getUsers/${companyId}`),
          ),
          ...companiesNames.data.map(({ companyId }) =>
            axios.get(
              `${process.env.REACT_APP_SETTINGS_ENDPOINT}company/${companyId}/list/${ListType.ACTIVATION_LETTER}`,
            ),
          ),
          ...companiesNames.data.map(({ companyId }) =>
            axios.get(
              `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${companyId}/settings`,
            ),
          ),
        ])
      ).map(p => ({ ...p, value: p.status === 'fulfilled' ? p.value : {} }));
      const respLength = resp.length / 3;
      for (let i = 0; i < respLength; i++) {
        if (
          resp[i].status === 'fulfilled' &&
          resp[i].value.data.detail.filter((user: IUserOverview) => checkLastAdmin(user)).length <=
            1
        ) {
          setCompanyWithLastAdmin(companiesNames.data[i]);
          setShowLoading(false);
          setIsDeleteUser(false);
          return;
        }
      }
      for (let i = respLength; i < respLength * 2; i++) {
        if (
          resp[i + respLength].status === 'fulfilled' &&
          resp[i + respLength].value.data.activationLetterRecipientMode === 'cu' &&
          resp[i].status === 'fulfilled' &&
          resp[i].value.data.detail.listEntries.length === 1 &&
          resp[i].value.data.detail.listEntries.filter(
            (user: ListEntries) => user.email === userInfo?.email,
          ).length === 1
        ) {
          setCompanyWithLastAdmin(companiesNames.data[i - respLength]);
          setIsLastLetterRecipient(true);
          setShowLoading(false);
          setIsDeleteUser(false);
          return;
        }
      }

      setShowLoading(false);
      setIsDeleteUser(true);
    } catch (err) {
      setShowLoading(false);
      showToast('error', {
        id: 'account.delete_user.error.failed_get_users',
        defaultMessage: 'Failed to receiving users for all companies',
      });
    }
  }, [companiesNames.data, userInfo?.email]);

  useEffect(() => {
    if (isSingleCompany) {
      dispatch(SettingsActions.getSettings({ companyId: companiesNames.data[0].companyId }));
      dispatch(
        SettingsActions.getDistributionList({
          companyId: companiesNames.data[0].companyId,
          listType: ListType.ACTIVATION_LETTER,
        }),
      );
    }
  }, [dispatch, companiesNames.data, isSingleCompany]);

  useEffect(() => {
    const getIsDeletePossibility = async () => {
      if (isSingleCompany) {
        let result = true;
        if (userInfo?.role.includes(CompanyRole.Admin) && data) {
          result = data?.filter(user => checkLastAdmin(user)).length > 1;
        }
        if (checkIfLastRecipient()) {
          result = false;
          setIsLastLetterRecipient(true);
        }

        setCompanyWithLastAdmin(companiesNames.data[0]);
        setIsDeleteUser(result);
      } else {
        setIsLastLetterRecipient(false);

        await getUsersForAllCompanies();

        setIsDeleteUser(true);
      }
    };

    getIsDeletePossibility();
  }, [
    companiesNames.data,
    data,
    userInfo?.role,
    userInfo?.email,
    checkIfLastRecipient,
    getUsersForAllCompanies,
    isSingleCompany,
  ]);

  const deletedUserInfo = data?.find(user => user.email === email);

  const deleteUserHandler = () => {
    if (isDeleteUser) {
      dispatch(
        UserManagementActions.changeUser({
          companyId: deletedUserInfo?.companyId,
          cognitoId: deletedUserInfo?.cognitoId,
          role: deletedUserInfo?.role,
          status: 'Deleted',
          fullName: `${userInfo?.name} ${userInfo?.surname}`,
          email,
          deleteOwnUser: true,
        }),
      );
      dispatch(AuthenticationActions.signOut());
      navigate(`/${language}/login`);
    } else {
      dispatch(CompanyActions.updateActiveCompany(companyWithLastAdmin));
      navigate(isLastLetterRecipient ? `/${language}/settings/1` : `/${language}/user-management`);
    }
  };

  const modalData = useMemo(
    () => getDeleteUserModalContent(intl, isDeleteUser, isLastLetterRecipient),
    [isDeleteUser, isLastLetterRecipient, intl],
  );

  return showLoading ||
    activationLetterDistributionListFetchingStatus === FetchingStatus.PENDING ? (
    <Loader show />
  ) : (
    <Dialog open={isOpen} onClose={closeModal}>
      <CloseIcon onClick={closeModal} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>{modalData.modalTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>{modalData.modalContent}</Typography>
      </DialogContent>

      <DialogActions>
        <CCPDefaultButton onClick={closeModal} variant='outlined'>
          {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
        <CCPDefaultButton
          onClick={deleteUserHandler}
          color={isDeleteUser ? 'error' : 'primary'}
          variant='contained'>
          {modalData.primaryCta}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
