import { IntlShape } from 'react-intl';

const getDeleteUserModalContent = (
  intl: IntlShape,
  isDeleteUser: boolean,
  isLastLetterRecipient: boolean,
) => {
  if (isDeleteUser) {
    return {
      modalTitle: intl.formatMessage({
        id: 'account.alert.delete_your_account.title',
        defaultMessage: 'Delete your account',
      }),
      modalContent: intl.formatMessage({
        id: 'account.alert.delete_your_account.subtitle',
        defaultMessage:
          'Deleting your account will permanently cancel access to the system. This action can’t be undone. Continue?',
      }),
      primaryCta: intl.formatMessage({
        id: 'form.employee.cta.delete_account',
        defaultMessage: 'Yes, I want delete my account',
      }),
    };
  }
  if (isLastLetterRecipient) {
    return {
      modalTitle: intl.formatMessage({
        id: 'account.alert.assign_new_letter_recipient.title',
        defaultMessage: 'Assign new activation letter recipient',
      }),
      modalContent: intl.formatMessage({
        id: 'account.alert.assign_new_letter_recipient.subtitle',
        defaultMessage:
          'You can not remove your account, since you’re only one activation letter recipient in the system. Please, assign new activation letter recipient',
      }),
      primaryCta: intl.formatMessage({
        id: 'account.alert.cta.assign_new_letter_recipient',
        defaultMessage: 'Go to settings',
      }),
    };
  } else {
    return {
      modalTitle: intl.formatMessage({
        id: 'account.alert.assign_new_administrator.title',
        defaultMessage: 'Assign new administrator',
      }),
      modalContent: intl.formatMessage({
        id: 'account.alert.assign_new_administrator.subtitle',
        defaultMessage: `You can not remove your account, since you’re only one Administrator in the system. Please, assign new Administrator`,
      }),
      primaryCta: intl.formatMessage({
        id: 'form.employee.cta.administration',
        defaultMessage: 'Administration',
      }),
    };
  }
};
export default getDeleteUserModalContent;
