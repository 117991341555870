import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse, CheckInsTableProps, GetCheckInsRequestParams } from 'types';

export const getCheckIns = createAsyncThunk<CheckInsTableProps[], GetCheckInsRequestParams>(
  'stats/getCheckIns',
  async ({ companyId, startMonth, endMonth }, { rejectWithValue }) => {
    const endpoint = `${process.env.REACT_APP_STATISTICS_ENDPOINT}graphql`;
    const body = {
      query: `
			{
				CompanyCheckins(filter:{company_id:{eq:"${companyId}"},date:{gte:"${startMonth}",lte:"${endMonth}"}}){
					start_month, end_month
					data { partner_name, eus, checkins }
				}
			}`,
    };
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.post(endpoint, body);

      return data.data.CompanyCheckins.data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.check_ins.get.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
