import ReactRouterPrompt from 'react-router-prompt';
import { useAppSelector } from '@store/index';
import GuardActivationLetters from './Dialogs/GuardActivationLetters';
import { FetchingStatus } from '@api/interfaces';
import { useCallback } from 'react';
import GuardSelfRegistrationChanges from './Dialogs/GuardSelfRegistrationChanges';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '@hooks/useLanguage';
import GuardRegistrationDeadline from './Dialogs/GuardRegistrationDeadline';
const RouterGuard = () => {
  const location = useLocation();
  const language = useLanguage();
  const {
    guardPopups: { activationLetters, registrationDeadline, selfRegistrationChanges },
  } = useAppSelector(state => state.globalState);

  const settingsFetchingStatus = useAppSelector(state => state.settings.settings.fetchingStatus);
  const signupFetchingStatus = useAppSelector(state => state.settings.selfRegistrationFetching);

  const blocker = useCallback(() => {
    if (
      settingsFetchingStatus !== FetchingStatus.PENDING &&
      signupFetchingStatus !== FetchingStatus.PENDING
    ) {
      return (
        activationLetters ||
        (location.pathname === `/${language}/settings/2` &&
          (registrationDeadline || selfRegistrationChanges))
      );
    }

    return false;
  }, [
    settingsFetchingStatus,
    signupFetchingStatus,
    activationLetters,
    registrationDeadline,
    selfRegistrationChanges,
    location.pathname,
    language,
  ]);

  return (
    <ReactRouterPrompt when={blocker}>
      {({ isActive, onConfirm, onCancel }) => {
        if (isActive) {
          if (activationLetters) {
            return <GuardActivationLetters onConfirm={onConfirm} onCancel={onCancel} />;
          }
          if (registrationDeadline) {
            return <GuardRegistrationDeadline onConfirm={onConfirm} onCancel={onCancel} />;
          }
          if (selfRegistrationChanges) {
            return <GuardSelfRegistrationChanges onConfirm={onConfirm} onCancel={onCancel} />;
          }
        }
      }}
    </ReactRouterPrompt>
  );
};

export default RouterGuard;
