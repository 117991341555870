import { Box } from '@mui/material';
import { SettingsActions } from 'features/settings/store';
import { useEffect } from 'react';
import { useAppDispatch } from 'store';

import {
  GeneralInfo,
  ChangePassword,
  NotificationSettings,
  PermissionSettings,
} from './UserAccountTabs';

export interface UserAccountProps {
  activeTab: number;
}

const UserAccountContent: React.FC<UserAccountProps> = ({ activeTab }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(SettingsActions.getOwnUserData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userAccountTabs = [
    <GeneralInfo key='generalInfo' />,
    <ChangePassword key='changePassword' />,
    <NotificationSettings key='notificationSettings' />,
    <PermissionSettings key='permissionSettings' />,
  ];

  return <Box className='content'>{userAccountTabs[activeTab]}</Box>;
};

export default UserAccountContent;
