import csvDownload from 'json-to-csv-export';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import MonthPicker from '../MonthPicker';
import TableLoader from '../TableLoader';
import { StatsInfoWrapper } from './StatsInfo.styles';
import { useIntl } from 'react-intl';
import {
  StatsInfoProps,
  StatsList,
  ActiveMembersTableProps,
  StatsTableProps,
} from '@localTypes/stats';
import { useStatsList } from '@utils/stats';
import formatNumber from '@utils/formatNumber';
import { useCallback, useMemo } from 'react';
import { CCPDefaultButton, Spacer } from '@components/index';
import * as XLSX from 'xlsx';
import { isActiveMembersRow } from '@utils/getStatsTypes';

const isActiveMember = (item: StatsTableProps): item is ActiveMembersTableProps =>
  isActiveMembersRow(item);

const StatsInfo = ({
  data,
  count,
  filters,
  fileName,
  className,
  isLoading = false,
  updateFilters,
  hasError,
}: StatsInfoProps) => {
  const location = useLocation();
  const statsList = useStatsList();
  const filterActiveRoutes = useMemo(() => {
    if (!statsList) {
      return {} as StatsList;
    }

    return statsList?.find(stat => location.pathname.includes(stat.link));
  }, [location.pathname, statsList]);
  const intl = useIntl();

  const getData = useCallback(
    (data: StatsTableProps[], filterActiveRoutes: StatsList) => {
      const translations = {
        eus: intl
          .formatMessage({
            id: 'dashboard__active_users',
            defaultMessage: 'active users',
          })
          .toUpperCase(),

        coupons: intl
          .formatMessage({ id: 'view.stats.table.coupon_used', defaultMessage: '# Coupon used' })
          .toUpperCase(),

        checkins: intl
          .formatMessage({ id: 'view.stats.checkins', defaultMessage: 'Check-Ins' })
          .toUpperCase(),

        partner_name: intl
          .formatMessage({
            id: 'view.stats.table.service',
            defaultMessage: 'Service provider',
          })
          .toUpperCase(),

        partner_id: intl.formatMessage({
          id: 'view.stats.table.id',
          defaultMessage: 'ID',
        }),
        customer_id: intl.formatMessage({
          id: 'view.stats.table.id',
          defaultMessage: 'ID',
        }),
        first_name: intl.formatMessage({
          id: 'form.employee.field.first_name.label',
          defaultMessage: 'First name',
        }),
        last_name: intl.formatMessage({
          id: 'form.employee.field.last_name.label',
          defaultMessage: 'Last name',
        }),
        member_name: intl.formatMessage({
          id: 'view.stats.table.member_name',
          defaultMessage: 'Member Full name',
        }),
        registration_date: intl.formatMessage({
          id: 'view.stats.table.registration_date',
          defaultMessage: 'Registration date',
        }),
      };
      return data
        .map(item => {
          const itemDuplicate = { ...item } as StatsTableProps & {
            member_name?: string;
          };
          const isActiveMemberTest = isActiveMember(itemDuplicate);

          if (isActiveMember(item)) {
            itemDuplicate.member_name = [item.first_name, item.last_name].join(' ');
          }

          return Object.keys(itemDuplicate).reduce((acc, key) => {
            if (isActiveMemberTest) {
              // Status is not needed in CSV
              if (key === 'status' || key === 'first_name' || key === 'last_name') return acc;
            }

            const newKey = translations[key as keyof typeof translations] || key;

            return {
              ...acc,
              [newKey]: itemDuplicate[key as keyof typeof item],
            };
          }, {});
        })
        .filter(Boolean);
    },
    [intl],
  );

  const downloadExcelFileHandler = useCallback(() => {
    if (!data || !filterActiveRoutes) return;

    const json = getData(data, filterActiveRoutes);
    const worksheet = XLSX.utils.json_to_sheet(json);
    worksheet['!cols'] = json.reduce(
      (res: { wch: number }[], item: Record<string, any>) =>
        Object.keys(item).map((m, i) => {
          const maxCellLength = res[i] ? res[i].wch : m.length;
          const currentCellLength = item?.[m] != null ? String(item[m]).length : 0;

          return { wch: Math.max(maxCellLength, currentCellLength) };
        }),
      [],
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });
  }, [data, fileName, filterActiveRoutes, getData]);

  const downloadCSVfileHandler = useCallback(() => {
    if (!data || !filterActiveRoutes) return;

    const dataToConvert = {
      data: getData(data, filterActiveRoutes),
      filename: fileName,
      delimiter: ',',
    };

    csvDownload(dataToConvert);
  }, [data, fileName, filterActiveRoutes, getData]);

  if (!filterActiveRoutes) return null;

  return (
    <StatsInfoWrapper>
      <Box className='top'>
        <Typography variant='h2'>{filterActiveRoutes?.title}</Typography>
        <Spacer size='sm' />
        <Typography variant='caption'>
          {intl.formatMessage({
            id: `view.stats.details.${filterActiveRoutes.name}.card.description`,
            defaultMessage: `View the number of ${filterActiveRoutes.title} for the selected time period and share statistic with others.`,
          })}
        </Typography>
        <Spacer size='lg' />
        <MonthPicker filters={filters} updateFilters={updateFilters} />
      </Box>

      <Box className='bottom'>
        {isLoading ? (
          <TableLoader />
        ) : (
          <>
            {!hasError ? (
              <Typography className={`bottom__count ${className}`} variant='h2'>
                {formatNumber(count)}
              </Typography>
            ) : (
              <Typography className={`bottom__count ${className}`} variant='h2'>
                {intl.formatMessage({ id: 'statistic.details.empty', defaultMessage: '-' })}
              </Typography>
            )}
            <Typography className='bottom__title' variant='body1'>
              {filterActiveRoutes?.title}
            </Typography>
            <Typography className='bottom__subtitle' variant='body1'>
              {intl.formatMessage({
                id: `view.stats.details.${filterActiveRoutes.name}.export.description`,
                defaultMessage: `Export the number of ${filterActiveRoutes.title} for the selected time period and share statistic with others.`,
              })}
            </Typography>
            <CCPDefaultButton
              variant='contained'
              onClick={downloadCSVfileHandler}
              disabled={!count || hasError || !data}>
              {intl.formatMessage({
                id: 'download.report.csv',
                defaultMessage: 'Download report in CSV',
              })}
            </CCPDefaultButton>
            <Spacer size='md' />
            <CCPDefaultButton
              variant='contained'
              onClick={downloadExcelFileHandler}
              disabled={!count || hasError || !data}>
              {intl.formatMessage({
                id: 'download.report.excel',
                defaultMessage: 'Download report in Excel',
              })}
            </CCPDefaultButton>
          </>
        )}
      </Box>
    </StatsInfoWrapper>
  );
};

export default StatsInfo;
