import { CompanySelectors } from '@features/company/store/company.selectors';
import { useLanguage } from '@hooks/useLanguage';
import { useAppSelector } from '@store/index';

export const useTranslationLanguage = () => {
  const currentLanguage = useLanguage();

  const {
    getCompany: { data: companyData },
  } = useAppSelector(CompanySelectors.getApi);

  const alternateLanguage = currentLanguage === 'de' ? 'en' : 'de';

  return (
    currentLanguage ||
    (companyData ? companyData.communicationLanguage.toLowerCase() : alternateLanguage)
  );
};
